import { Map, Feature, View } from 'ol';
import { Style, Stroke, Fill, Circle } from 'ol/style';
import { GeoJSON } from 'ol/format';
import { Point } from 'ol/geom';
import { OSM, XYZ, Vector as VectorSource } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { fromLonLat, get as getProjection } from 'ol/proj';
import { Select } from 'ol/interaction'
import { click } from 'ol/events/condition';
import { bbox as boundingBox } from 'ol/loadingstrategy';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import ProfileStyle from 'ol-ext/style/Profile';
import ProfileControl from 'ol-ext/control/Profile'
import tracks from './prepare/tracks.json'

proj4.defs('EPSG:21781',
    '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 ' +
    '+x_0=600000 +y_0=200000 +ellps=bessel ' +
    '+towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs');
register(proj4);

const CH_LV03 = getProjection('EPSG:21781');
const WGS84 = getProjection('EPSG:4326');
const Mercator = getProjection('EPSG:3857');
const OSMLayer = new TileLayer({
    source: new OSM()
});
const SwisstopoLayer = new TileLayer({
    source: new XYZ({ url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg' })
});

const Filters = {
    Wandern: "Wandern",
    Bike: "Bike",
    Velo: "Velo",
    Schneeschuh: "Schneeschuh",
    Swim: "Swim",
    Plan: "Plan",
    Other: "Other"
};

const TrackStyle = {
    Highlight: "Highlight",
    Background: "Background",
    Normal: "Normal",
    Hide: "Hide",
};

function getFilter(id) {
    switch (id) {
        case 1774:
            return Filters.Wandern;
        case 1773:
            return Filters.Bike;
        case 1779:
            return Filters.Velo;
        case 79387:
            return Filters.Schneeschuh;
        case 77273:
            return Filters.Plan;
        case 160146:
            return Filters.Swim;
    }
    return Filters.Other;
}

function getStyle(filter, trackStyle) {
    const trackWidth = function () {
        switch (trackStyle) {
            case TrackStyle.Highlight:
                return 4;
            case TrackStyle.Background:
                return .8;
            default:
                return 3;
        }
    }();
    let lineColor = function () {
        switch (filter) {
            case Filters.Wandern:
                return '#00f';
            case Filters.Bike:
                return '#a2294b';
            case Filters.Velo:
                return '#000';
            case Filters.Schneeschuh:
                return '#555';
            case Filters.Swim:
                return '#93b';
            case Filters.Plan:
                return '#cc0';
        }
        return "#fff";
    }();

    if (trackStyle == TrackStyle.Hide) {
        lineColor = '#00000000';
    }

    const stroke = new Stroke({
        color: lineColor,
        width: trackWidth
    });
    if (trackStyle == TrackStyle.Highlight) {
        return new ProfileStyle({
            stroke: stroke,
            fill: new Fill({ color: [25, 25, 25, .4] }),
            scale: .1
        });
    }
    return new Style({ stroke: stroke });
}

function createLayerFromId(trackID, filter) {
    const url = "https://map.simonste.ch/data/" + trackID + ".geojson"
    return createLayer(url, filter);
}

function createLayer(path, filter) {
    const urlFilter = window.location.hash.replace('#id=', ''); // urlParameter: /?#id...
    const tagFilter = window.location.hash.replace('#tag=', '').toLowerCase(); // urlParameter: /?#tag...
    let style = TrackStyle.Normal;
    if (urlFilter.length > 0) { style = TrackStyle.Background; }
    if (tagFilter.length > 0) { style = TrackStyle.Hide; }
    const vectorSource = new VectorSource({
        format: new GeoJSON(),
        loader: function (extent, resolution, projection) {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', path);
            let onError = function () {
                vectorSource.removeLoadedExtent(extent);
            }
            xhr.onerror = onError;
            xhr.onload = function () {
                if (xhr.status == 200 && xhr.responseText.startsWith('{')) {
                    let features = vectorSource.getFormat().readFeatures(xhr.responseText, {
                        dataProjection: CH_LV03,
                        featureProjection: Mercator
                    });
                    let feature = features[0];
                    vectorSource.addFeature(feature);

                    if (feature.id_ == urlFilter) {
                        highlight.dispatchEvent({
                            type: 'select',
                            selected: [feature],
                            deselected: []
                        });
                    }

                    if (tagFilter.length > 0 && feature.values_.tags !== undefined) {
                        if (feature.values_.tags.toString().toLowerCase().includes(tagFilter)) {
                            tagged.dispatchEvent({
                                type: 'show',
                                selected: [feature],
                                deselected: []
                            });
                        }
                    }
                } else {
                    console.log('failed to load ' + path)
                    onError();
                }
            }
            xhr.send();
        },
        strategy: boundingBox,
    });
    return new VectorLayer({
        source: vectorSource,
        style: getStyle(filter, style),
        projection: CH_LV03,
    });
}

let highlight = new Select({
    condition: click,
});
let tagged = new Select({
});
let element = document.getElementById('info');

function get_duration(data, filter) {
    let min = 0;
    if (data.tracked_time) {
        min = data.tracked_time.toFixed(0)
    } else {
        switch (filter) {
            case Filters.Wandern:
                min = data.meta.walking;
                break;
            case Filters.Bike:
            case Filters.Velo:
                min = data.meta.biking
                break;
            case Filters.Schneeschuh:
                min = data.meta.snowshoe_trekking;
                break;
            default:
                break;
        }
    }
    if (min > 60) {
        return Math.floor(min / 60) + 'h ' + Math.floor(min % 60) + 'min';
    }
    return min + 'min';
}

function popup(selected_track, id) {
    let trackName = selected_track.name;
    let date = selected_track.userdate;

    let length = (selected_track.meta.length / 1000).toFixed(2) + " km";
    let up = "↑ " + (selected_track.meta.totalup).toFixed(0) + " m";
    let down = "↓ " + (selected_track.meta.totaldown).toFixed(0) + " m";
    let duration = "⏱ " + get_duration(selected_track, getFilter(selected_track.filterid));

    element.getElementsByClassName('title')[0].innerHTML = "<a href='/?#id=" + id + "'>" + trackName.substring(7) + "</a> @" + date;
    element.getElementsByClassName('meta')[0].innerHTML = "<table><tr><td>" + length + "</td><td>" + duration + "</td></tr><tr><td>" +
        up + "</td><td>" + down + "</td></tr></table>";
}

function setToBackgroundExcept(filter) {
    element.style.display = 'none'
    let map = document.getElementById("map").data;
    map.getLayers().getArray().forEach(function (layer) {
        if (layer.getSource().getFeatures) {
            layer.getSource().getFeatures().forEach(function (f) {
                if (getFilter(f.values_.filterid) == filter || filter == Filters.Other) {
                    f.setStyle(getStyle(getFilter(f.values_.filterid), TrackStyle.Normal));
                } else {
                    f.setStyle(getStyle(getFilter(f.values_.filterid), TrackStyle.Background));
                }
            })
        }
    });
}

function toggleBackgroundLayer() {
    const map = document.getElementById("map").data;
    let osmLayer = map.getLayers().getArray().at(1);
    osmLayer.setVisible(!osmLayer.getVisible());

    let backgroundLayer = document.getElementById("backgroundLayer");
    let hiddenBackgroundLayer = document.getElementById("hiddenBackgroundLayer");

    [backgroundLayer.src, hiddenBackgroundLayer.src] = [hiddenBackgroundLayer.src, backgroundLayer.src]

    console.log("osmLayer " + osmLayer.getVisible());
}

function drawMap() {
    let map = new Map({
        target: 'map',
        layers: [SwisstopoLayer, OSMLayer],
        view: new View({
            center: fromLonLat([8.25, 46.75]),
            zoom: 8,
        })
    });
    document.getElementById("map").data = map;

    for (let track of tracks) {
        const filter = getFilter(track['filter_id']);
        if ([Filters.Wandern, Filters.Bike, Filters.Velo, Filters.Schneeschuh, Filters.Swim].includes(filter)) {
            map.addLayer(createLayerFromId(track['id'], filter));
        }
    }

    const profile = new ProfileControl({
        target: element.getElementsByClassName('profileChart')[0]
    });
    map.addControl(profile);

    let pt = new Feature(new Point([0, 0]));
    let overlay = new VectorLayer({ source: new VectorSource() });
    overlay.getSource().addFeature(pt);
    overlay.setMap(map);
    profile.on(["over", "out"], function (e) {
        if (!pt) return;
        if (e.type == "over") {
            // Show point at coord
            pt.setGeometry(new Point(e.coord));
            pt.setStyle(new Style({
                image:
                    new Circle({
                        radius: 6,
                        fill: new Fill({ color: 'yellow' }),
                        stroke: new Stroke({ color: 'red', width: 2 })
                    })
            }));
        } else {
            pt.setStyle([]); // hide
        }
    });

    map.addInteraction(highlight);
    highlight.setHitTolerance(20);
    highlight.on('select', function (e) {
        if (e.selected.length > 0) {
            setToBackgroundExcept(Filters.Other);

            element.style.display = 'block'
            popup(e.selected[0].values_, e.selected[0].id_);
            if (e.selected[0].values_.geometry.flatCoordinates[3] != 0) {
                // timestamp coordinate starts with 0 when available
                console.log("no elevation data");
            }

            const filter = getFilter(e.selected[0].values_.filterid);
            e.selected[0].setStyle(getStyle(filter, TrackStyle.Highlight));
            profile.setGeometry(e.selected[0]);
            // Zoom to feature
            map.getView().fit(e.selected[0].getGeometry().getExtent(), { padding: [50, 50, 50, 50] });
        } else {
            element.style.display = 'none'
            const filter = getFilter(e.deselected[0].values_.filterid);
            e.deselected[0].setStyle(getStyle(filter, TrackStyle.Normal));
        }
    });
    tagged.on('show', function (e) {
        if (e.selected.length > 0) {
            const filter = getFilter(e.selected[0].values_.filterid);
            e.selected[0].setStyle(getStyle(filter, TrackStyle.Normal));
        }
    });
}

drawMap();

document.getElementById("backgroundLayer").addEventListener('click', function () { toggleBackgroundLayer() });
document.getElementById("alle").addEventListener('click', function () { setToBackgroundExcept(Filters.Other) });
document.getElementById("velo").addEventListener('click', function () { setToBackgroundExcept(Filters.Velo) });
document.getElementById("bike").addEventListener('click', function () { setToBackgroundExcept(Filters.Bike) });
document.getElementById("wandern").addEventListener('click', function () { setToBackgroundExcept(Filters.Wandern) });
document.getElementById("skitour").addEventListener('click', function () { setToBackgroundExcept(Filters.Schneeschuh) });
document.getElementById("swim").addEventListener('click', function () { setToBackgroundExcept(Filters.Swim) });